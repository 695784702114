
  import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {},
  methods: {},
  computed: {},
})
  export default class Intersect extends Vue {
  @Prop({ type: Number, default: 50 }) height: number
  @Prop({ type: Boolean, default: false }) loading: number

  onIntersection ([entry]) {
    entry && entry.isIntersecting && this.$emit('intersect', entry)
  }
  }
