
  import { Component, Prop } from 'vue-property-decorator'
  import { formFilter } from '@/graphql/generated-types'
  import { Debounce } from '@/utils/decorators'
  import Intersect from '@/components/core/Intersect.vue'
  import { LoggedActions } from '@/entities/audit'
  import { User } from '@/entities/public'
  import GTimelineItem from '@/components/core/view/GTimelineItem.vue'
  import { FormStructure } from '@/components/forms/FormStructure'
  import { extract } from '@/graphql/forms'
  import { plainToInstance } from 'class-transformer'
  import { VCol, VRow, VTimeline, VTimelineItem } from 'vuetify/lib/components'

@Component({
  components: { VRow, VCol, VTimelineItem, VTimeline, GTimelineItem, Intersect },
})
  export default class GTimeline extends FormStructure {
  @Prop({ type: Number }) uid!: number
  @Prop({ type: Number }) containerHeight!: number
  @Prop({ type: Number }) minHeight!: number
  private page = 1
  private limit = 20
  private offset = 0
  isLastPage = false
  isLoading = false
  logs: LoggedActions[] = []
  newLogs = []
  users: User[] = []
  input = null
  nonce = 0

  async mounted () {
    await this.search()
  }

  @Debounce(500)
  async loadNextPage () {
    if (!this.isLoading) {
      await this.search()
    }
  }

  async search () {
    const { structure: { api: { model } } } = this
    let { page, offset, limit, logs, uid } = this
    this.isLoading = true

    const schema = {
      lead: 'crm',
      person: 'persons',
    }

    const filter: formFilter = {
      schema_name: { _eq: schema[model.toLowerCase()] },
      table_name: { _eq: model.toLowerCase() },
      row_data: { _contains: { id: uid } },
    }
    if (logs.length) {
      const records = await this.fetchData({
        query: { name: 'aggregate', model: 'LoggedActions' },
        filter,
        force: true,
      })
      const { aggregate: { count } } = records

      if (count / limit <= page) {
        this.isLoading = false
        this.isLastPage = true
        return
      }
      offset = (this.page++) * limit
    }

    const response = await this.fetchData({
      query: { name: 'find', model: 'LoggedActions' },
      filter: { ...filter, hasura_user: { _is_null: false } },
      force: true,
    })

    const usersEmails = response?.filter(user => 'x-hasura-user-email' in user.hasuraUser).map(user => user.hasuraUser['x-hasura-user-email'])
    this.users = await this.fetchData({
      query: { name: 'find', model: 'User' },
      filter: { email: { _in: [...usersEmails] } },
    })
    logs.push(...(await this.fetchData({
      query: { name: 'fetch', model: 'LoggedActions' },
      filter,
      offset,
      limit,
      force: true,
    })))
    this.isLoading = false

    const { Model } = extract({ name: 'fetch', model })

    this.newLogs = await Promise.all(
      logs.map(async log => {
        const isInsert = log.action === 'I'

        if (!log?.changedFields && !isInsert) return

        const changesCamelCase = isInsert ? log.newRow : log.newChanges
        const changeRaw = isInsert ? log.rowData : log.changedFields
        const instance = plainToInstance(Model, changesCamelCase)

        Object.assign(instance, changeRaw)
        const items = await this.mapFields(instance)

        if (!items?.length) return

        return {
          action: log.actionText,
          email: log.email,
          date: log.date,
          icon: log.icon,
          items,
        }
      })
    )
    this.newLogs = this.newLogs.filter(_ => _)
  }

  findPersonName (email) {
    const { users } = this

    if (!email || !users?.length) return 'Sistema'

    const user = users.find(user => user.email === email)

    return user.person.name || ''
  }

  render (h) {
    const timelineItems = this.newLogs.map((log, i) =>
      h(
        'v-timeline-item',
        {
          key: i,
          props: {
            color: 'primary',
            fillDot: true,
            small: true,
            icon: log.icon,
          },
          style: {
            display: 'flex',
            alignItems: 'center',
          },
        },
        [
          h(
            'v-row',
            {
              props: {
                align: 'start',
                justify: 'space-between',
                noGutters: true,
              },
            },
            [
              h('g-timeline-item', {
                props: {
                  person: this.findPersonName(log.email),
                  log,
                },
              }),
            ]
          ),
        ]
      )
    )
    const { minHeight, isLastPage, isLoading } = this
    return h(
      'v-row',
      {
        props: {
          justify: 'start',
          noGutters: true,
        },
        class: ['pr-2'],
        style: {
          overflowY: 'auto',
          maxHeight: `${minHeight}px`,
        },
      },
      [
        h(
          'v-timeline',
          {
            props: {
              dense: true,
              alignTop: true,
            },
            style: {
              maxWidth: '400px',
            },
          },
          [timelineItems],
        ),
        isLastPage
          ? null
          : h('intersect', {
            props: { loading: isLoading },
            on: { intersect: this.loadNextPage },
          }),
      ]
    )
  }
  }
